import React from 'react'
import './animation.css'

function Animation() {
  return (
    <div>
        <div className="square"></div>
        <div className="circle"></div>
    </div>
  )
}

export default Animation