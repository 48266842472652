import React from 'react'

function SingleOfficial({official}) {
  return (
    <div className='leader-container rounded-md p-[4px]'>
       <img src={official?.image} alt='' className="rounded-md"/>    
        <div className='name-container px-2'>
            <p className='text-black font-bold text-lg' >{official?.name}</p>
            <p className='text-gray-600 -mt-[2px] font-medium' >{official?.position}</p>
        </div>
                   
    </div>
  )
}

export default SingleOfficial