export const officialData = [
    {
        id:1,
        name:'David Wambua Kioko',
        image:require('../../assets/chairperson.jpg'),
        position:'Chairperson'
    },
    {
        id:2,
        name:'Doxa Jepkorir Kutto',
        image:require('../../assets/vicechair.jpg'),
        position:'Vice chairperson'
    },
    {
        id:3,
        name:"James Alung'ata",
        image:require('../../assets/secretary.jpg'),
        position:'Secretary'
    },
    {
        id:4,
        name:'Barrister Rhotford',
        image:require('../../assets/vicesec.jpg'),
        position:'Vice Secretary'
    },
    {
        id:5,
        name:'Ian Onyango',
        image:require('../../assets/outreach.jpg'),
        position:'Outreach Chairperson'
    },
    {
        id:6,
        name:'Robert Cheruiyot',
        image:require('../../assets/bibles.jpg'),
        position:'Bible study  Chairperson'
    },
    {
        id:7,
        name:'Georgina Wambui',
        image:require('../../assets/discipleship.jpg'),
        position:'Discipleship Chairpersin'
    },
    {
        id:8,
        name:'Veronicah Wambui Mathenge',
        image:require('../../assets/service.jpg'),
        position:'Service subcommittee chairperson'
    },
    {
        id:9,
        name:'Daniel Mwinzila',
        image:require('../../assets/prayer.jpg'),
        position:'Prayer Secretary'
    },
   {
        id:10,
        name:'Sharon Nyagoha Bunyali',
        image:require('../../assets/treasurer.jpg'),
        position:'Tresurer'
    },
    {
        id:11,
        name:'Benaiah Baraka',
        image:require('../../assets/assetsm.jpg'),
        position:'Assets Manager'
    },
    {
        id:12,
        name:'Shadrach Makau',
        image:require('../../assets/prayers.jpg'),
        position:'Prayer subcommittee member'
    },
    {
        id:13,
        name:'Immaculate Mbithe',
        image:require('../../assets/intercessor.jpg'),
        position:'Assets Manager'
    },
    {
        id:14,
        name:'Brian Karanja',
        image:require('../../assets/choir.jpg'),
        position:'Choir Coordinator'
    },
    {
        id:15,
        name:'Emmanuel Kyalo',
        image:require('../../assets/ushering.jpg'),
        position:'Ushering'
    }
];